<template>
  <div id="items">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">{{ cardTitle }}</h4>
            <div class="d-flex">
              <button
                type="button"
                class="btn btn-primary d-flex align-items-center"
                @click="exportData"
              >
                Exportar
              </button>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <Search @search="updateSearch" />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <DataTable
                  :sorter="query.sorter"
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-export="false"
                  :base-route="`${baseRoute}/view/`"
                  @sorterValue="(event) => (query.sorter = event)"
                  @row-clicked="handleRowClick"
                >
                  <template #actions="{ id }">
                    <DatatableEdit :url="`${baseRoute}/view/${id}`" />
                  </template>
                </DataTable>
              </CCol>
            </CRow>
            <CRow class="mt-2">
              <CCol lg="12">
                <Pagination
                  :page="query.page"
                  :per-page="query.perpage"
                  :pages="pagination.pages"
                  :total="pagination.total"
                  :items="pagination.items"
                  @update:active-page="(page) => (query.page = page)"
                  @update:perpage="(perpage) => (query.perpage = perpage)"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DataTable from '@/components/ui/Datatable'
import DatatableEdit from '@/components/ui/DatatableActions/Edit'
import Pagination from '@/components/ui/Pagination'
import Search from '@/components/ui/Search'
import Service from '@/services/leads.service'
import leads from './leads'
import axios from '@/services/axios.service'
import FileSaver from 'file-saver'
import moment from 'moment'

export default {
  metaInfo: {
    title: 'Leads',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_leads')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    DataTable,
    DatatableEdit,
    Pagination,
    Search
  },

  computed: {
    baseRoute() {
      return `/leads/${this.$route.meta.type}`
    },
    cardTitle() {
      return `Leads - ${this.$route.meta.name}`
    },
    lead() {
      const type = this.$route.meta.type
      return leads[type]
    }
  },

  data: () => ({
    service: null,
    datatable: {
      items: [],
      fields: []
    },
    query: {
      search: '',
      page: 1,
      perpage: localStorage.getItem('perPageItem')
        ? parseFloat(localStorage.getItem('perPageItem'))
        : 10,
      pagination: true,
      status: null,
      sorter: {
        asc: false,
        column: 'created_at'
      }
    },
    pagination: {
      total: 0,
      pages: 0,
      items: 0
    },

    rowCliked: {}
  }),

  watch: {
    query: {
      handler: 'updateDataTable',
      deep: true
    }
  },

  async created() {
    this.service = new Service(this.lead.uri)
    await this.updateDataTable()
    this.datatable.fields = this.lead.cols
  },

  methods: {
    async updateDataTable() {
      const response = await this.service.index(this.query)
      const { data, pagination } = response

      if (data) {
        this.pagination = {
          ...pagination,
          pages: pagination.lastPage,
          items: data.length
        }

        this.datatable.items = response.data.map((item) => {
          const [day, hour] = item.created_at.split(' ')
          let send_at = new Intl.DateTimeFormat('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour12: false,
            timeZone: 'America/Los_Angeles'
          }).format(new Date(day))
          send_at = `${send_at} ${hour}`
          send_at = moment(item.created_at).format('DD/MM/YYYY HH:mm')

          return { ...item, created_at: send_at }
        })
      }
    },

    async updateSearch(searchText) {
      this.query.search = searchText
      this.query.page = 1

      await this.updateDataTable()
    },

    handleRowClick(event) {
      this.rowCliked = event
    },

    async exportData() {
      try {
        const file = await axios.get(`export/leads/${this.$route.meta.type}`, {
          responseType: 'blob'
        })

        const xls = file.request.response

        FileSaver.saveAs(xls, `RTE Iveco - ${this.cardTitle}.xlsx`)
      } catch (error) {
        return error
      }
    }
  }
}
</script>

<style lang="scss">
#items {
  .orderButton {
    margin-right: 10px;
  }

  .thumb {
    width: 80px !important;
  }
}
</style>
